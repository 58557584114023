<template>
  <div class="min-h-screen bg-gray-100 p-8">
    <!-- Header -->
    <div class="w-full py-4 flex justify-between items-center">
      <h1 class="text-4xl font-bold text-blue-600 m-0">SeatSeeker Dashboard</h1>
      <button @click="signOut" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 text-sm rounded">
        Sign Out
      </button>
    </div>

    <!-- Your Reservations Section -->
    <div class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Your Reservations</h2>
      <div v-if="Object.keys(groupedReservations).length" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div v-for="(reservations, restaurant) in groupedReservations" :key="restaurant" class="bg-white p-4 rounded-lg shadow">
          <div class="flex justify-between items-center">
            <p class="font-semibold text-lg">{{ restaurant }}</p>
            <button @click="openReservationModal(restaurant, reservations)" class="bg-blue-500 text-white px-3 py-1 rounded">
              Expand
            </button>
          </div>
        </div>
      </div>
      <p v-else class="text-gray-600">No reservations made yet.</p>
    </div>

    <!-- Reservations Modal -->
    <div v-if="showReservationModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div class="bg-white p-4 rounded-lg shadow-lg max-w-3xl w-full">
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-2xl font-semibold">{{ selectedRestaurant }}</h3>
          <button @click="closeReservationModal" class="text-gray-600 hover:text-gray-800 text-2xl font-bold">&times;</button>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div v-for="reservation in selectedReservations" :key="reservation.reservationId" class="p-4 bg-gray-100 rounded-lg">
            <p>Date: {{ formatDate(reservation.date) }}</p>
            <p>Time: {{ formatTime(reservation.time) }}</p>
            <p>Party Size: {{ reservation.partySize }}</p>
            <p>Status: {{ reservation.status }}</p>
            <button @click="deleteReservation(reservation.reservationId)" class="bg-red-500 text-white px-3 py-1 rounded mt-2 flex items-center justify-center" :disabled="loadingReservationId === reservation.reservationId">
              <span v-if="loadingReservationId === reservation.reservationId" class="spinner"></span>
              <span v-else>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Submit a New Reservation Section -->
    <div>
      <h2 class="text-2xl font-semibold mb-4">Submit a New Reservation</h2>
      <form @submit.prevent="showCredentialsModal = true" class="bg-white p-4 rounded-lg shadow space-y-4">
        <input v-model="newReservation.restaurant" type="text" placeholder="Name of Restaurant" class="input" required>
        <input v-model="newReservation.date" type="date" class="input" required>
        <input v-model="newReservation.time" type="time" class="input" required>
        <input v-model="newReservation.partySize" type="number" placeholder="Party Size" class="input" min="1" required>

        <!-- When to Run Section -->
        <div>
          <h3 class="text-lg font-semibold">When to Run</h3>
          <input v-model="newReservation.scheduleDate" type="date" class="input" required>
          <input v-model="newReservation.scheduleTime" type="time" class="input" required>
          <select v-model="newReservation.timezone" class="input">
            <option value="US/Eastern">Eastern Time</option>
            <option value="America/Chicago">Central Time</option>
          </select>
        </div>

        <button type="button" @click="showCredentialsModal = true" class="w-full bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700">Submit Reservation</button>
      </form>
    </div>

    <!-- Credentials Modal -->
    <div v-if="showCredentialsModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div class="bg-white p-4 rounded-lg shadow">
        <h3 class="text-lg font-semibold mb-4">Enter Resy Credentials</h3>
        <!-- Input Grid Container with Adjusted Spacing -->
        <div class="grid grid-cols-2 gap-x-4 gap-y-2">
          <!-- First Name Input -->
          <input v-model="resyCredentials.firstName" type="text" placeholder="First Name" class="input" required>
          <!-- Last Name Input -->
          <input v-model="resyCredentials.lastName" type="text" placeholder="Last Name" class="input" required>
          <!-- Email Input -->
          <input v-model="resyCredentials.email" type="email" placeholder="Resy Email" class="input" required>
          <!-- Password Input -->
          <input v-model="resyCredentials.password" type="password" placeholder="Resy Password" class="input" required>
        </div>
        <div class="flex justify-between mt-4">
          <button @click="closeCredentialsModal" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>
          <div>
            <button v-if="!isLoading" type="button" @click="submitReservation" class="w-full bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700">Submit</button>
            <div v-else class="flex justify-center">
              <div class="spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Auth } from 'aws-amplify';
import axios from 'axios';

export default {
  name: 'Dashboard',
  data() {
    return {
      reservations: [],
      newReservation: {
        restaurant: "",
        date: "",
        time: "",
        partySize: "",
        scheduleDate: "",
        scheduleTime: "",
        timezone: "US/Eastern",
      },
      showReservationModal: false,
      showCredentialsModal: false,
      selectedRestaurant: null,
      selectedReservations: [],
      resyCredentials: {
        firstName: "",
        lastName: "",
        email: "",
        password: ""
      },
      isLoading: false,
      loadingReservationId: null, // Track the reservation ID being deleted
    };
  },
  async created() {
    await this.fetchReservations();
  },
  computed: {
    groupedReservations() {
      return this.reservations.reduce((acc, reservation) => {
        if (!acc[reservation.restaurant]) {
          acc[reservation.restaurant] = [];
        }
        acc[reservation.restaurant].push(reservation);
        return acc;
      }, {});
    },
  },
  methods: {
    async fetchReservations() {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload['cognito:username'];

        const response = await axios.get(`${process.env.VUE_APP_RESERVATIONS_API}?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });

        this.reservations = response.data.reservations;
      } catch (error) {
        console.error("Error fetching reservations:", error);
      }
    },
    async submitReservation() {
      this.isLoading = true;
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload['cognito:username'];

        const payload = {
          ...this.newReservation,
          username,
          resyCredentials: this.resyCredentials,
        };

        await axios.post(`${process.env.VUE_APP_RESERVATIONS_API}`, payload, {
          headers: {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${token}`
          }
        });

        this.showCredentialsModal = false;
        await this.fetchReservations();
        this.resetForm();
      } catch (error) {
        console.error("Error submitting reservation:", error);
        this.showCredentialsModal = false;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteReservation(reservationId) {
      try {
        if (!reservationId) {
          console.error("Reservation ID is undefined");
          return;
        }

        this.loadingReservationId = reservationId; // Start loading spinner

        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload['cognito:username'];

        await axios.delete(`${process.env.VUE_APP_RESERVATIONS_API}?id=${reservationId}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          },
          data: {
            username
          }
        });

        await this.fetchReservations();

        // Update the selected reservations and close the modal if no reservations left
        this.selectedReservations = this.selectedReservations.filter(res => res.reservationId !== reservationId);
        if (this.selectedReservations.length === 0) {
          this.closeReservationModal();
        }
      } catch (error) {
        console.error("Error deleting reservation:", error);
      } finally {
        this.loadingReservationId = null; // Stop loading spinner
      }
    },
    openReservationModal(restaurant, reservations) {
      this.selectedRestaurant = restaurant;
      this.selectedReservations = reservations;
      this.showReservationModal = true;
    },
    closeReservationModal() {
      this.selectedRestaurant = null;
      this.selectedReservations = [];
      this.showReservationModal = false;
    },
    closeCredentialsModal() {
      this.showCredentialsModal = false;
    },
    resetForm() {
      this.newReservation = {
        restaurant: "",
        date: "",
        time: "",
        partySize: "",
        scheduleDate: "",
        scheduleTime: "",
        timezone: "US/Eastern",
      };
      this.resyCredentials = { email: "", password: "", firstName: '', lastName: '',};
    },
    async signOut() {
      try {
        await Auth.signOut();
        this.$router.push('/'); // Redirect to the login page after signing out
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    },
    formatDate(dateString) {
      // Split the date string and reformat it without conversion
      const [year, month, day] = dateString.split('-');
      const date = new Date(year, month - 1, day);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    formatTime(timeString) {
      const [hours, minutes] = timeString.split(':');
      const date = new Date();
      date.setHours(hours, minutes);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
  },
}
</script>
<style scoped>
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-left-color: #fff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0; /* Gray-300 */
}

.custom-title {
  /* Custom CSS for precise alignment */
  margin-left: -.5rem; /* Example of nudging the title further to the left */
}
</style>
