import { createRouter, createWebHistory } from 'vue-router';
import { Auth  } from 'aws-amplify';
import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';
import Dashboard from "@/components/Dashboard.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetPassword.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/reset-password/:username?',
        name: 'ResetPassword',
        // You'll need to create a ResetPassword component similar to ForgotPassword
        component: ResetPassword,
        props: true
    }

    // Define other routes as needed
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Navigation Guard TODO
router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        try {
            await Auth.currentAuthenticatedUser();
            next(); // User is authenticated, proceed to the route
        } catch (error) {
            next('/'); // User is not authenticated, redirect to home or login page
        }
    } else {
        next(); // Route does not require authentication, proceed
    }
});

export default router;
