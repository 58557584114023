<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow rounded-2xl sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-blue-600 mb-4">Reset Password</h2>
        <form class="space-y-6" @submit.prevent="resetPassword">
          <div>
            <label for="code" class="block text-sm font-medium text-gray-700">Verification Code</label>
            <div class="mt-1">
              <input v-model="code" id="code" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>
          <div>
            <label for="newPassword" class="block text-sm font-medium text-gray-700">New Password</label>
            <div class="mt-1">
              <input v-model="newPassword" id="newPassword" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>
          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'ResetPassword',
  data() {
    return {
      code: '',
      newPassword: '',
      username: '' // Assume username is needed; adjust according to your app's flow
    };
  },
  mounted() {
    // Optional: Retrieve username from route parameters or provide a way for users to input it
    this.username = this.$route.params.username || '';
  },
  methods: {
    async resetPassword() {
      if (!this.code || !this.newPassword || !this.username) {
        alert('Verification code, new password, and username are required.');
        return;
      }
      try {
        await Auth.forgotPasswordSubmit(this.username, this.code, this.newPassword);
        alert('Password reset successfully. You can now log in with your new password.');
        this.$router.push({ name: 'Login' }); // Navigate back to the login page on successful reset
      } catch (error) {
        console.error('Error resetting password:', error);
        alert('Failed to reset password. Please try again.');
      }
    }
  }
};
</script>
