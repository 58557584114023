import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles.css';
import { Amplify } from 'aws-amplify';

Amplify.configure({
    Auth: {
        // Amazon Cognito Region
        region: process.env.VUE_APP_COGNITO_REGION,

        // Amazon Cognito User Pool ID
        userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,

        // Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,

        // Optional, for configuring cookie storage. e.g.
        // cookieStorage: {
        //     domain: 'yourapp.com',
        //     path: '/',
        //     expires: 365,
        //     secure: true
        // },

        // Optional, specifying the OAuth flow type. e.g.
        // oauth: {}
    }
});

createApp(App).use(router).mount('#app');

