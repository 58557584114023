<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow rounded-2xl sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-blue-600 mb-4">Welcome to SeatSeeker</h2>
        <form class="space-y-6" @submit.prevent="submitForm">
          <div>
            <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
            <div class="mt-1">
              <input v-model="username" id="username" name="username" type="text" autocomplete="username" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>
          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
            <div class="mt-1">
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>
          <div v-if="errorMessage" class="text-red-500 text-sm text-center mb-2">
            {{ errorMessage }}
          </div>
          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sign in</button>
          </div>
        </form>
        <div class="mt-6">
          <div class="text-sm text-center">
            <router-link to="/forgot-password" class="font-medium text-indigo-600 hover:text-indigo-500">Forgot your password?</router-link>
          </div>
          <div class="text-sm text-center mt-4">
            Don’t have an account?
            <router-link to="/register" class="font-medium text-indigo-600 hover:text-indigo-500">Create Account</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  created() {
    this.checkUser();
  },
  methods: {
    async checkUser() {
      try {
        await Auth.currentAuthenticatedUser();
        this.$router.replace({ name: 'Dashboard' }); // Redirect to dashboard if user is already signed in
      } catch (error) {
        // User not signed in, stay on login page
        console.log(error)
      }
    },
    async submitForm() {
      this.errorMessage = ''; // Reset error message at the beginning of submission
      if (!this.username || !this.password) {
        alert('Username and password are required.');
        return;
      }
      try {
        await Auth.signIn({
          username: this.username,
          password: this.password
        });


        this.$router.push('/dashboard'); // Navigate to the dashboard on success
      } catch (error) {
        console.error('Error signing in', error);
        // Set a user-friendly error message
        this.errorMessage = 'Incorrect username or password.';
      }
    }
  }
}
</script>

<style scoped>
/* You can add additional styles here */
</style>
