<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow rounded-2xl sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-blue-600 mb-4">Create your account</h2>
        <form class="space-y-6" @submit.prevent="!isConfirmStep ? signUp() : confirmSignUp()">
          <div v-if="!isConfirmStep">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
              <input v-model="email" id="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
            <div>
              <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
              <input v-model="username" id="username" type="text" autocomplete="username" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
              <input v-model="password" id="password" type="password" autocomplete="new-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
            <div>
              <label for="confirm-password" class="block text-sm font-medium text-gray-700">Confirm Password</label>
              <input v-model="confirmPassword" id="confirm-password" type="password" autocomplete="new-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <p v-if="password && confirmPassword && password !== confirmPassword" class="text-red-500 text-sm mt-2">Passwords do not match.</p>
            </div>
          </div>
          <div v-if="isConfirmStep">
            <div>
              <label for="confirmationCode" class="block text-sm font-medium text-gray-700">Confirmation Code</label>
              <input v-model="confirmationCode" id="confirmationCode" type="text" placeholder="Enter your confirmation code" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>
          <div>
            <button :disabled="password !== confirmPassword && !isConfirmStep" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" :class="{'bg-gray-500 hover:bg-gray-500': password !== confirmPassword && !isConfirmStep}">
              {{ isConfirmStep ? 'Confirm Sign Up' : 'Create Account' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'Register',
  data() {
    return {
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      isConfirmStep: false,
    };
  },
  methods: {
    async signUp() {
      if (this.password !== this.confirmPassword) {
        alert("Passwords do not match.");
        return;
      }
      try {
        await Auth.signUp({
          username: this.username,
          password: this.password,
          attributes: {
            email: this.email, // Correctly specify email attribute here
          }
        });
        this.isConfirmStep = true; // Move to confirmation step
        alert("Sign up successful! Please check your email for the confirmation code.");
      } catch (error) {
        console.error('Error signing up:', error);
        alert("Error signing up: " + error.message);
      }
    },
    async confirmSignUp() {
      try {
        await Auth.confirmSignUp(this.username, this.confirmationCode);
        alert("Your account has been confirmed!");
        this.$router.push('/dashboard'); // or '/dashboard' as appropriate after confirmation
      } catch (error) {
        console.error('Error confirming sign up:', error);
        alert("Error confirming sign up: " + error.message);
      }
    },
  },
};
</script>
