<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow rounded-2xl sm:px-10">
        <h2 class="text-center text-3xl font-extrabold text-blue-600 mb-4">Reset Password</h2>
        <form class="space-y-6" @submit.prevent="submitForgotPassword">
          <div>
            <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
            <div class="mt-1">
              <input v-model="username" id="username" type="username" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>
          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Send Reset Code</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async submitForgotPassword() {
      try {
        await Auth.forgotPassword(this.username);
        this.$router.push({ name: 'ResetPassword', params: { username: this.username } }); // Redirect to reset password page
      } catch (error) {
        console.error('Error sending forgot password request', error);
      }
    }
  }
}
</script>
